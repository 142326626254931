var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("FILTROS")]),_c('v-card-text',[_c('ValidationObserver',{ref:"obs"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-row',{staticClass:"d-flex justify-center mb-6"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Tours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"clearable":"","items":_vm.listProduct,"item-text":"name","item-value":"id","error-messages":errors,"success":valid,"label":"Seleccionar Tour"},model:{value:(_vm.filter.idTours),callback:function ($$v) {_vm.$set(_vm.filter, "idTours", $$v)},expression:"filter.idTours"}})}}])})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","hide-details":"auto","dense":"","label":"FILTRAR POR DIA","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filter.menu),callback:function ($$v) {_vm.$set(_vm.filter, "menu", $$v)},expression:"filter.menu"}},[_c('v-date-picker',{model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"cars"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"clearable":"","items":_vm.listTransport,"item-text":"plate","item-value":"id","error-messages":errors,"success":valid,"label":"Seleccionar Carro"},model:{value:(_vm.filter.idTransport),callback:function ($$v) {_vm.$set(_vm.filter, "idTransport", $$v)},expression:"filter.idTransport"}})}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{staticClass:"d-flex justify-space-around mb-6"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchReserva}},[_vm._v("BUSCAR")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.formReservation = true}}},[_vm._v("CREAR")]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","loading":_vm.loadingDownload},on:{"click":_vm.downloadXlsx}},[_vm._v("DESCARGAR")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"multi-sort":"","loading":_vm.loadingPackages,"search":_vm.search,"headers":_vm.headersReservation,"items":_vm.listReserva(),"items-per-page":20},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.editReservationItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteReservationItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"900"},model:{value:(_vm.formReservation),callback:function ($$v) {_vm.formReservation=$$v},expression:"formReservation"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Reserva")])]),_c('v-card-text',[_c('ValidationObserver',{ref:"obs"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"dense":"","items":_vm.listPersons,"item-text":"nombreCompletos","item-value":"id","placeholder":"INNOUT SAC","label":"Buscar Clientes","data-vv-name":"form.idCliente","data-vv-as":"Tipo","filled":"","rounded":""},model:{value:(_vm.form.idCliente),callback:function ($$v) {_vm.$set(_vm.form, "idCliente", $$v)},expression:"form.idCliente"}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":""}},'v-btn',attrs,false),on),[_c('b',[_vm._v("Agregar Cliente")])])],1)],1)]}}]),model:{value:(_vm.dialogformClient),callback:function ($$v) {_vm.dialogformClient=$$v},expression:"dialogformClient"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Cliente")]),_c('v-card-text',[_c('ValidationObserver',{ref:"formClient"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"Numero Documento","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":8,"error-messages":errors,"success":valid,"label":"Numero Documento","required":""},model:{value:(_vm.formClient.numeroDocumento),callback:function ($$v) {_vm.$set(_vm.formClient, "numeroDocumento", $$v)},expression:"formClient.numeroDocumento"}})}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"E-mail","required":""},model:{value:(_vm.formClient.correo),callback:function ($$v) {_vm.$set(_vm.formClient, "correo", $$v)},expression:"formClient.correo"}})}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Nombres","required":""},model:{value:(_vm.formClient.nombres),callback:function ($$v) {_vm.$set(_vm.formClient, "nombres", $$v)},expression:"formClient.nombres"}})}}])})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Apellidos","required":""},model:{value:(_vm.formClient.apellidos),callback:function ($$v) {_vm.$set(_vm.formClient, "apellidos", $$v)},expression:"formClient.apellidos"}})}}])})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"telefono","rules":"required|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":9,"error-messages":errors,"success":valid,"label":"Numero Telefonico","required":""},model:{value:(_vm.formClient.telefono),callback:function ($$v) {_vm.$set(_vm.formClient, "telefono", $$v)},expression:"formClient.telefono"}})}}])})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Direccion","required":""},model:{value:(_vm.formClient.direccion),callback:function ($$v) {_vm.$set(_vm.formClient, "direccion", $$v)},expression:"formClient.direccion"}})}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogformClient = false}}},[_vm._v("Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.submitClient}},[_vm._v(" Crear ")])],1)],1)],1)],1),_c('v-form',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('ValidationProvider',{attrs:{"name":"Precio Venta","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Precio Venta","required":""},model:{value:(_vm.form.precioVenta),callback:function ($$v) {_vm.$set(_vm.form, "precioVenta", $$v)},expression:"form.precioVenta"}})}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('ValidationProvider',{attrs:{"name":"Abono","rules":("required|numeric|max_value:" + (_vm.form.precioVenta))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Abono","required":""},model:{value:(_vm.form.abono),callback:function ($$v) {_vm.$set(_vm.form, "abono", $$v)},expression:"form.abono"}})}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Hotel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Hotel","required":""},model:{value:(_vm.form.hotel),callback:function ($$v) {_vm.$set(_vm.form, "hotel", $$v)},expression:"form.hotel"}})}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Referido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"items":_vm.listCollaboratorsOperador(),"item-text":"nombreCompletos","item-value":"id","error-messages":errors,"success":valid,"label":"Referido","required":""},model:{value:(_vm.form.ref),callback:function ($$v) {_vm.$set(_vm.form, "ref", $$v)},expression:"form.ref"}})}}])})],1)],1)],1)],1)],1),_c('small',[_vm._v("*indica que se requiere el campo")])],1),_c('v-card',[_c('v-card-title',[_vm._v("PAQUETES")]),_c('v-card-text',[_c('ValidationObserver',{ref:"packs"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Tours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"items":_vm.listProduct,"error-messages":errors,"success":valid,"item-text":"name","item-value":"id","label":"Seleccionar Tours","required":""},model:{value:(_vm.formPacks.idPack),callback:function ($$v) {_vm.$set(_vm.formPacks, "idPack", $$v)},expression:"formPacks.idPack"}})}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"pasajero","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Pasajeros","required":""},model:{value:(_vm.formPacks.passenger),callback:function ($$v) {_vm.$set(_vm.formPacks, "passenger", $$v)},expression:"formPacks.passenger"}})}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Fecha"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha del Tours","required":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formPacks.day),callback:function ($$v) {_vm.$set(_vm.formPacks, "day", $$v)},expression:"formPacks.day"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.formPacks.menu3),callback:function ($$v) {_vm.$set(_vm.formPacks, "menu3", $$v)},expression:"formPacks.menu3"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.formPacks.menu3 = false}},model:{value:(_vm.formPacks.day),callback:function ($$v) {_vm.$set(_vm.formPacks, "day", $$v)},expression:"formPacks.day"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"blue",attrs:{"small":"","disabled":_vm.disableAddPack,"dark":""},on:{"click":_vm.addPack}},[_vm._v("Agregar")])],1)],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"multi-sort":"","loading":_vm.loadingPackages,"search":_vm.search,"headers":_vm.headers,"items":_vm.listPackages,"items-per-page":20},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeFormReservation}},[_vm._v("CERRAR")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loadingResult},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.isEdit ? 'EDITAR' : 'CREAR')+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.clientRepeat),callback:function ($$v) {_vm.clientRepeat=$$v},expression:"clientRepeat"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Advertencia ")]),_c('v-card-text',{staticClass:"lg-4"},[_vm._v(" Ya tenemos almacenado al cliente en nuestra base de datos. Cualquier cambio que se quiera hacer acerca del cliente, lo puede hacer editandolo. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.clientRepeat = false}}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.errorDownload),callback:function ($$v) {_vm.errorDownload=$$v},expression:"errorDownload"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Error al descargar ")]),_c('v-card-text',{staticClass:"lg-4"},[_vm._v(" Estos fueron las Reservas que no se pudieron asignar un vehiculo:"+_vm._s(_vm.reservationUndesignated)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.errorDownload = false}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }